const statusBADT = [
  {
    status: 0,
    count: 0,
    name: 'Đang chờ',
    class: 'bg-yellow-400'
  },
  {
    status: 1,
    count: 0,
    name: 'Đang làm Bệnh Án',
    class: 'bg-yellow-800'
  },
  {
    status: 2,
    count: 0,
    name: 'Đang đánh giá',
    class: 'bg-yellow-400'
  },
  {
    status: 3,
    count: 0,
    name: 'Đang điều trị',
    class: 'bg-green-600'
  },
  {
    status: 4,
    count: 0,
    name: 'Kết thúc điều trị',
    class: 'bg-grey-700'
  }
]

export default statusBADT
