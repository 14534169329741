const statusAppt = [
  {
    status: 1,
    name: 'CHỜ KHÁM',
    class: 'text-yellow-800'
  },
  {
    status: 2,
    name: 'ĐANG KHÁM',
    class: 'text-blue-700'
  },
  {
    status: 3,
    name: 'CHỜ KẾT QUẢ',
    class: 'text-yellow-600'
  },
  {
    status: 4,
    name: 'CHỜ ĐỌC KẾT QUẢ',
    class: 'text-yellow-700'
  },
  {
    status: 5,
    name: 'ĐÃ KHÁM',
    class: 'text-green-700'
  },
  {
    status: 6,
    name: 'Hủy khám',
    class: 'text-red-700'
  }
]

export default statusAppt
