<template>
  <div
    class="modal fade"
    id="modal__add_patient"
  >
    <div
      class="modal-dialog modal-lg shadow"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tạo mới Bệnh nhân</h5>
        </div>
        <div class="modal-body txt-grey-900">
          <div class="row pb-3.5 mb-3.5 border-b-1">
            <div class="col-md-6">
              <label class="fs-14 fw-500 required">Họ và tên</label>
              <input
                v-model="form.name"
                @blur="checkPatient"
                type="text"
                class="form--input fw-500"
                placeholder="Nhập Họ và tên"
              />
            </div>

            <div class="col-md-6">
              <label class="fs-14 fw-500 required">CCCD/Mã định danh</label>
              <input
                v-model="form.national_id"
                @blur="checkPatient"
                type="text"
                class="form--input fw-500"
                placeholder="Nhập CCCD/Mã định danh"
              />
              <div
                class="text-red-500 text-xs"
                v-if="patient && form.national_id && form.national_id === patient.national_id"
              >CCCD/Mã
                định
                danh đã tồn tại trên hệ thống</div>
            </div>
          </div>
          <div class="mt- row">
            <div class="col-md-6 flex">
              <div class="col-md-4">
                <label
                  for="nam"
                  class="fs-14 fw-500 txt-grey-900 required"
                >Giới tính</label>
              </div>
              <label
                for="nam"
                class="d-flex align-items-center mr-4 fs-16 txt-grey-800"
              ><input
                  type="radio"
                  id="nam"
                  :value="1"
                  class="mr-2"
                  v-model="form.gender"
                />Nam</label>
              <label
                for="nu"
                class="d-flex align-items-center mr-4 fs-16 txt-grey-800"
              ><input
                  type="radio"
                  id="nu"
                  :value="2"
                  class="mr-2"
                  v-model="form.gender"
                />Nữ</label>
            </div>
            <div class="col-md-6">
              <div class="col-md-8">
                <label
                  for="bd"
                  class="fs-14 fw-500"
                >Ngày sinh</label>
              </div>
              <input
                v-model="form.birthday"
                type="date"
                name="bd"
                id="bd"
                placeholder="Chọn ngày"
                class="form--input fw-500"
              />
            </div>
          </div>
          <div class="mt-6 row">
            <div class="col-md-6">
              <label class="fs-14 fw-500">SĐT bệnh nhân</label>
              <input
                v-model="form.phone"
                type="text"
                class="form--input fw-500"
                placeholder="Nhập SĐT"
              />
              <div
                class="text-red-500 text-xs"
                v-if="patient && form.phone && form.phone === patient.phone"
              >SĐT đã tồn
                tại trên hệ
                thống</div>
            </div>
            <div class="col-md-6">
              <label class="fs-14 fw-500 txt-grey-900">Nghề nghiệp</label>
              <input
                v-model="form.career"
                type="text"
                class="form--input fs-16 fw-500 txt-grey-900"
                placeholder="Nhập Nghề nghiệp"
              />
            </div>
          </div>

          <div class="mt-6 row">
            <div class="col-md-6">
              <label class="fs-14 fw-500">SĐT người thân</label>
              <input
                v-model="form.contact_phone"
                type="text"
                class="form--input fw-500"
                placeholder="Nhập SĐT"
              />
            </div>
            <div class="col-md-6">
              <label class="fs-14 fw-500 txt-grey-900">Số BHYT</label>
              <input
                v-model="form.insurance_number"
                type="text"
                class="form--input fs-16 fw-500 txt-grey-900"
                placeholder="Nhập Số BHYT"
              />
              <div
                class="text-red-500 text-xs"
                v-if="patient && form.insurance_number && form.insurance_number === patient.insurance_number"
              >
                Số
                BHYT đã tồn tại trên hệ thống</div>
            </div>
          </div>

          <div class="mt-6 row">
            <div class="col-md-6">
              <label class="fs-14 fw-500 txt-grey-900">Mã bệnh nhân</label>
              <input
                v-model="form.patient_code"
                type="text"
                class="form--input fs-16 fw-500 txt-grey-900"
                placeholder="Nhập Mã bệnh nhân"
              />
            </div>
          </div>

          <div
            class="mt-6"
            v-show="error"
          >
            <div class="text-red-500">Thông tin bệnh nhân đã tồn tại trên hệ thống!</div>
          </div>

          <div class="mt-7 text-sm font-medium flex justify-end">
            <button
              @click="postPatientsWS"
              :disabled="!isSubmit"
              class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
            >
              <div>Tạo mới</div>
            </button>
            <button
              @click="showModalAddPatient(false)"
              class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
            >
              <div>Hủy</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'ModalAddPatient',
  data () {
    return {
      form: {
        gender: 1,
        national_id: null,
        name: '',
        insurance_number: '',
        patient_code: '',
        phone: '',
        contact_phone: '',
        birthday: null,
        career: null
      },
      error: false,
      patient: null,
      isSubmit: false
    }
  },
  mounted () {
    const self = this
    window.$(document).ready(function () {
      window.$('#modal__add_patient').on('hidden.bs.modal', function (e) {
        self.resetDate()
      })
    })
  },
  methods: {
    resetDate () {
      this.form.gender = 1
      this.form.national_id = null
      this.form.name = ''
      this.form.insurance_number = ''
      this.form.patient_code = ''
      this.form.contact_phone = ''
      this.form.birthday = null
      this.form.career = null
      this.form.phone = ''
      this.error = false
      this.patient = null
    },
    showModalAddPatient (show) {
      window.$('#modal__add_patient').modal(show ? 'show' : 'hide')
    },
    async postPatientsWS () {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        name: self.form.name,
        national_id: self.form.national_id,
        insurance_number: self.form.insurance_number,
        phone: self.form.phone,
        contact_phone: self.form.contact_phone,
        gender: self.form.gender,
        clinic_id: this.$globalClinic.id,
        patient_code: self.form.patient_code,
        birthday: self.form.birthday,
        career: self.form.career
      }
      let r = await this.$rf.getRequest('DoctorRequest').postPatientsWS(params).then((r) => {
        const data = r.data
        if (data) this.$emit('complete', data)
        this.showModalAddPatient(false)
        this.submit = false
        return r
      }).catch((e) => {
        console.log(e)
        return false
      })
      return r
    },
    checkPatient () {
      if (this.form.national_id && this.form.name) {
        this.postCheckPatient()
      } else this.isSubmit = false
    },
    async postCheckPatient () {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        name: self.form.name,
        national_id: self.form.national_id,
        insurance_number: self.form.insurance_number,
        phone: self.form.phone,
        contact_phone: self.form.contact_phone,
        gender: self.form.gender,
        clinic_id: this.$globalClinic.id,
        patient_code: self.form.patient_code,
        birthday: self.form.birthday,
        career: self.form.career
      }
      let r = await this.$rf.getRequest('DoctorRequest').postCheckPatient(params, null, false).then((r) => {
        this.patient = r.data
        if (_.isEmpty(this.patient)) {
          this.error = false
          this.isSubmit = true
        } else {
          this.error = true
          this.isSubmit = false
        }
        return r
      }).catch((e) => {
        console.log(e)
        return false
      })
      return r
    }
  }
}
</script>
<style scoped>
.border-b-1 {
  border-bottom: 1px solid #efefef;
}
</style>