<template>
    <div class="w-100 m-0 p-2">
      <div class="table-responsive overflow-hidden">
        <div class="row">
          <div class="col-sm-4">
            <label class="form-control-label robo-20-500 mt-3 mb-2">Thời gian mỗi ca tư vấn <span class="text--red">*</span></label>
            <div class="input-group bg-gray">
              <input type="number" v-model="times" min="0" class="form-control" @change="changeTime()">
              <span class="input-group-text border-0">phút</span>
            </div>
          </div>
          <div class="col-sm-4">
            <label class="form-control-label robo-20-500 mt-3 mb-2">Thời gian chi phí lịch hẹn <span class="text--red">*</span></label>
            <div class="input-group bg-gray">
              <input type="number" v-model="price" min="0" class="form-control" @change="changePrice()">
              <span class="input-group-text border-0">VNĐ</span>
            </div>
          </div>
        </div>
        <div>
          <table v-if="weeks_new" class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr>
                <th class="text-center">Ngày</th>
                <th class="text-center">Thời gian</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody class="h-25">
              <tr v-for="day in weeks_new" :key="day.day" class="mt-2 mb-2 border-bottom">
                <div class="form-check form-check-inline">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox" v-model="day.status">
                    <label class="form-check-label robo-16-500" for="inlineCheckbox">{{day.name}}</label>
                  </div>
                </div>
                <td>
                  <div class="mt-2" v-for="(session, index) in day.sessions" :key="index" v-if="day.status">
                    <div class="row" v-if="session.status">
                      <div class="col-lg-5">
                        <input type="time" v-model="session.start" class="w-100 form-control" @change="changeWorkTime(day, session.id, session.start, session.end)">
                      </div>
                      <div class="col-lg-5">
                        <input type="time" v-model="session.end" class="w-100 form-control" @change="changeWorkTime(day, session.id, session.start, session.end)">
                      </div>
                      <div class="col-lg-2" style="line-height: 44px;">
                        <img src="../../public/assets/images/icon/icon-delete.svg" style="height: 24px; width: 24px; cursor: pointer;" @click="deleteSessionDay(day.day, session.id, index)">
                      </div>
                      <div class="col-12 text-center"><span class="font-size-10 text-danger">{{session.error}}</span></div>
                    </div>
                  </div>
                </td>
                <td><a class="txt-pri robo-20-400" href="javascript:void(0)" @click="addSessionDay(day.day)" v-if="day.status">+ Thêm</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
       <div align="right" class="w-100 mt-3">
        <!-- <button type="button" @click="deleteAll()" class="btn btn-outline-primary mr-2">Hoàn nguyên</button> -->
        <button type="button" @click="changeWeeksNew()" class="btn btn-add bg--primary" :disabled="loading">
          <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
          {{$t('button.save')}}
        </button>
      </div>
    </div>
</template>
<script>
export default {
  name: 'WorkTime',
  props: ['weeks', 'loading', 'weeks_new'],
  data () {
    return {
      times: 15,
      price: 0,
      change_times: false,
      change_price: false,
      doctor_clinic: null,
      loaded: true,
      quick: {
        name: 'Cài đặt nhanh',
        sessions: [{id: null, type: 1, start: '', end: '', status: false, error: ''}, {
          id: null,
          type: 2,
          start: '',
          end: '',
          status: false,
          error: ''
        }, {id: null, type: 3, start: '', end: '', status: false, error: ''}]
      },
      list_update_time: []
    }
  },
  mounted () {
    let self = this
    self.doctor_clinic = this.$globalClinic
    self.times = self.doctor_clinic && self.doctor_clinic.distance_time ? self.changeMinutes(self.doctor_clinic.distance_time) : 15
    self.price = self.doctor_clinic && self.doctor_clinic.distance_price ? self.doctor_clinic.distance_price : 0
    window.$(document).ready(function () {
      window.$('#modal__worktime').on('hide.bs.modal', function (e) {
        self.deleteAll()
        self.$emit('onReload')
      })
    })
  },
  methods: {
    addSessionDay (day) {
      let self = this
      self.weeks_new.forEach((element, index) => {
        var ele = {
          id: null,
          type: 1,
          start: '',
          end: '',
          error: '',
          status: true
        }
        if (element.day === day) {
          element.sessions.push(ele)
        }
      })
    },
    deleteSessionDay (day, id, index) {
      let self = this
      var arr = self.weeks_new.filter(item => item.day === day)
      if (arr && arr.length > 0) {
        arr[0].sessions[index].status = false
        // console.log(arr[0].sessions[index].status)
      }
    },
    formatTime (time) {
      return window.moment(new Date(time)).format('HH:mm')
    },
    sessionName (type) {
      return type === 1 ? this.$t('title.morning') : type === 2 ? this.$t('title.afternoon') : type === 3 ? this.$t('title.evening') : ''
    },
    changeMinutes (time) {
      var arr = time.match(/^(\d{2}):(\d{2}):(\d{2})$/)
      return parseInt(arr[1]) * 60 + parseInt(arr[2])
    },
    quickInstallation (type) {
      let self = this
      let params = {
        day: 0,
        type: 0,
        start_time: '',
        end_time: ''
      }
      self.weeks.forEach(day => {
        if (day.sessions[type].status && day.sessions[type].start && day.sessions[type].end) {
          params.day = day.day
          params.type = type
          params.start_time = day.sessions[type].start
          params.end_time = day.sessions[type].end
        }
      })
      self.autoInstallation(params)
    },
    autoInstallation (params) {
      let self = this
      try {
        this.loaded = false
        self.weeks.forEach(day => {
          if (!day.sessions[params.type].start && !day.sessions[params.type].end) {
            day.sessions[params.type].day = day.day
            day.sessions[params.type].status = true
            day.sessions[params.type].start = params.start_time
            day.sessions[params.type].end = params.end_time
          }
          if (!day.sessions[params.type].start && !day.sessions[params.type].end) {
            day.sessions[params.type].day = day.day
            day.sessions[params.type].status = true
            day.sessions[params.type].start = params.start_time
            day.sessions[params.type].end = params.end_time
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loaded = true
      }
    },
    changeAllWeek () {
      let self = this
      try {
        self.loaded = false
        let validated = true
        self.quick.sessions.forEach(item => {
          if (item.start && item.end) {
            if (item.start > item.end) {
              item.error = 'Thời gian bắt đầu không lớn hơn thời gian kết thúc'
              validated = false
            } else {
              if (item.type === 1) {
                if (item.start > '12:00' || item.end > '12:00') {
                  item.error = 'Thời gian phải nằm trong khoảng 00:00 đến 12:00!'
                  validated = false
                }
              } else if (item.type === 2) {
                if (item.start > '17:00' || item.end > '17:00' || item.start < '12:00' || item.end < '12:00') {
                  item.error = 'Thời gian phải nằm trong khoảng 12:00 đến 17:00!'
                  validated = false
                }
              } else if (item.type === 3) {
                if (item.start < '17:00' || item.end < '17:00') {
                  item.error = 'Thời gian phải nằm trong khoảng 17:00 đến 23:59!'
                  validated = false
                }
              }
            }
          }
        })
        if (validated) {
          self.quick.sessions.forEach(item => {
            if (item.status) {
              let params = {
                day: 0,
                type: item.type - 1,
                start_time: item.start,
                end_time: item.end
              }
              self.autoInstallation(params)
            }
          })
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
        this.loaded = true
      }
    },
    changeWeeksNew () {
      let self = this
      try {
        self.loaded = false
        var validated = true
        self.weeks_new.forEach(day => {
          if (day.status) {
            day.sessions.forEach(item => {
              if (item.status) {
                if (item.start && item.end) {
                  if (item.start > item.end) {
                    item.error = 'Thời gian bắt đầu không lớn hơn thời gian kết thúc'
                    validated = false
                  } else {
                    if (item.start < '00:00' || item.end > '24:00') {
                      item.error = 'Thời gian phải nằm trong khoảng 00:00 đến 24:00!'
                      validated = false
                    }
                  }
                }
              }
            })
          }
        })
        if (validated) {
          self.onSubmitNew()
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
        this.loaded = true
      }
    },
    onSubmitNew () {
      let self = this
      try {
        self.loaded = false
        self.weeks_new.forEach(day => {
          if (day.status) {
            day.sessions.forEach(item => {
              if (item.status) {
                if (!item.id && item.start && item.end) {
                  let params = {
                    day: day.day,
                    type: 1,
                    start_time: item.start,
                    end_time: item.end
                  }
                  // if (item.start >= '00:00' && item.end < '12:00') {
                  //   params.type = 1
                  // } else if (item.start >= '12:00' && item.end < '17:00') {
                  //   params.type = 2
                  // } else if (item.start >= '17:00' && item.end <= '23:59') {
                  //   params.type = 3
                  // }
                  if (item.start >= '00:00' && item.start < '12:00') {
                    params.type = 1
                  } else if (item.start < '17:00') {
                    params.type = 2
                  } else {
                    params.type = 3
                  }
                  self.$emit('addWorkTime', params)
                }
              } else {
                self.$emit('removeWorkTime', item.id)
                self.removeWorkTime(item.id)
              }
            })
            if (self.list_update_time) {
              self.list_update_time.forEach(function (element, index) {
                let param = {
                  day: day.day,
                  type: 1,
                  start_time: element.start,
                  end_time: element.end
                }
                self.$emit('postDoctorWorkTimeEdit', element.id, param)
              })
            }
          } else {
            if (day.sessions) {
              day.sessions.forEach(item => {
                if (item) {
                  self.$emit('removeWorkTime', item.id)
                }
              })
            }
          }
        })
        if (self.change_times || self.change_price) {
          self.$emit('changeTime', self.times, self.price)
        }
        self.$emit('alterWorkTime')
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
        this.loaded = true
      }
    },
    deleteAll () {
      let self = this
      self.times = self.doctor_clinic && self.doctor_clinic.distance_time ? self.changeMinutes(self.doctor_clinic.distance_time) : 15
      self.deleteInstallation(0)
      self.deleteInstallation(1)
      self.deleteInstallation(2)
    },
    deleteInstallation (type) {
      let self = this
      try {
        this.loaded = false
        self.weeks.forEach(day => {
          if (!day.sessions[type].id) {
            day.sessions[type].status = false
            day.sessions[type].start = ''
            day.sessions[type].end = ''
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        this.loaded = true
      }
    },
    onValidateForm () {
      let self = this
      let validated = true
      self.weeks.forEach(day => {
        day.sessions.forEach(session => {
          if (session.start && session.end) {
            if (session.start > session.end) {
              session.error = 'Thời gian bắt đầu không được lớn hơn kết thúc!'
              validated = false
            } else {
              if (session.type === 1) {
                if (session.start > '12:00' || session.end > '12:00') {
                  session.error = 'Thời gian phải nằm trong khoảng 00:00 đến 12:00!'
                  validated = false
                }
              } else if (session.type === 2) {
                if (session.start > '17:00' || session.end > '17:00' || session.start < '12:00' || session.end < '12:00') {
                  session.error = 'Thời gian phải nằm trong khoảng 12:00 (12:00 CH) đến 17:00 (05:00 CH)!'
                  validated = false
                }
              } else if (session.type === 3) {
                if (session.start < '17:00' || session.end < '17:00') {
                  session.error = 'Thời gian phải nằm trong khoảng 17:00 đến 23:59!'
                  validated = false
                }
              }
            }
          }
        })
      })
      if (validated) {
        self.onSubmit()
      }
    },
    onSubmit () {
      let self = this
      try {
        self.weeks.forEach(day => {
          day.sessions.forEach(session => {
            let params = {
              day: day.day,
              type: session.type,
              start_time: '',
              end_time: ''
            }
            if (session.status && session.start && session.end) {
              params.start_time = session.start
              params.end_time = session.end
              self.$emit('addWorkTime', params)
            } else {
              if (session.id && session.start && session.end) {
                self.$emit('removeWorkTime', session.id)
              }
            }
          })
        })
        if (self.change_times || self.change_price) {
          self.$emit('changeTime', self.times, self.price)
        }
        self.$emit('alterWorkTime')
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
      }
    },
    changeTime () {
      let self = this
      self.change_times = true
    },
    changePrice () {
      let self = this
      self.change_price = true
    },
    changeWorkTime (day, id, start, end) {
      let self = this
      try {
        var param = {
          id: id,
          start: start,
          end: end,
          day: day
        }
        if (id) {
          if (!self.list_update_time) {
            self.list_update_time.push(param)
          } else {
            var arr = self.list_update_time.filter(item => parseInt(item.id) === parseInt(id))
            if (!(arr && arr[0])) {
              self.list_update_time.push(param)
            } else {
              arr[0].start = start
              arr[0].end = end
            }
          }
        }
        // console.log(self.list_update_time)
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    removeWorkTime (id) {
      let self = this
      try {
        if (self.list_update_time) {
          self.list_update_time = self.list_update_time.filter(item => parseInt(item.id) !== parseInt(id))
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style scoped>
.input-time-worktime {
    line-height: 15px;
    height: 30px;
    font-size: 11px;
    background-color: white
}
</style>